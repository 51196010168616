import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PortfolioTileInner = ({ node }) => {
    const title = () => {
        if (node.subtitle != null && node.shouldShowSubtitle) {
            return node.title + " // " + node.subtitle
          } else {
            return node.title
          }
    }

    return (
        <>
        <GatsbyImage image={getImage(node.thumbnail.localFile)} alt={node.title} />
        <p>{title()}</p>
        </>
    )
}

export default PortfolioTileInner