import * as React from "react"
import PortfolioTilesOuter from "../../components/portfolioTilesOuter"
import { graphql } from "gatsby"

const MusicVideoPage = (props) => {
    const nodes = props.data.contentfulPortfolioOrder.order
    return (
        <PortfolioTilesOuter nodes={nodes} subfolder="/music-videos/" />
    )
}

export default MusicVideoPage

export const query = graphql`
query allMusicVideos {
  contentfulPortfolioOrder(category: {eq: "Music Video"}) {
    order {
      id
      title
      subtitle
      shouldShowSubtitle
      slug
      thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`

export const Head = () => {
    return (
        <>
            <title>MUSIC VIDEOS - BO MIRHOSSENI</title>
        </>
    )
}