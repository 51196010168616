import React from "react"
import Layout from "./layout"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Link } from "gatsby"
import PortfolioTileInner from "./portfolioTileInner"

const PortfolioTilesOuter = ({ nodes, subfolder }) => (
    <Layout>
        <TransitionGroup className="portfolio-tiles" appear={true}>
            {nodes.map((node) => (
                <CSSTransition timeout={1000} classNames="fade" key={node.id}>
                    <div className="portfolio-tile">
                        <Link to={subfolder + node.slug}>
                            <PortfolioTileInner node={node} />
                        </Link>
                    </div>
                </CSSTransition>
            ))}
        </TransitionGroup>
    </Layout>
)

export default PortfolioTilesOuter